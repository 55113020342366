angular.module('CaresApp').service('AppointmentOperations', [
  '$http',
  function($http) {
    const self = this;

    self.close = function(appointmentId, endDate) {
      const url = `/api/appointmentOp/close/${appointmentId}`;
      return $http.post(url, {
        date: endDate,
      });
    }
  },
]);
