angular.module('CaresApp').service('OcrLookups', [
  '$q',
  'ActivityTypeService',
  'ActivityTypeStatusService',
  'AppointmentCapacityService',
  'AttorneyRoleService',
  'BillableItemTypeService',
  'CaseStatusService',
  'CaseTypeService',
  'CaseTypeAttorneyRoleService',
  'CountyService',
  'ExceptionStatusService',
  'ExceptionAttachmentService',
  'EducationalSettingService',
  'GenderService',
  'HearingTypeService',
  'InvoiceStatusService',
  'InvolvedPersonTypeService',
  'JudicialDistrictService',
  'PermanencyGoalService',
  'PersonaApplicableToService',
  'PrivilegeHolderService',
  'PlacementService',
  'PlacementTypeService',
  'RaceEthnicityService',
  'StateService',
  'ActivityGroupService',
  'PersonaService',
  'PersonaPermissionService',
  'PermissionService',
  'UserStatusService',
  'OfficeStatusService',
  'OfficeService',
  'ActivityStatusService',
  'HelpArticleCategoryService',
  'CaseTypeBillingAllocationService',
  'BillableItemTypeStandardRateService',
  'AppointmentEndReasonService',
  'ExcessFeesRequestStatusService',
  'AppointmentAttachmentService',
  'ChildPartyService',
  'ContactService',
  'HelpArticleService',
  'UserService',
  'ApplicationSettingsService',
  'ActivityAttachmentService',
  'RequestTypeService',
  'ChildPartyCourtAttendanceOptionService',
  'SavedPlacementService',
  'AppointmentOfficeAccessRequestReasonService',
  'AppointmentOfficeAccessRequestStatusService',
  'VendorService',
  'HelpTextService',
  'UrlLinkTextService',
  'BestInterestsContinuationReasonService',
  'RoleTypeService',
  function($q, ActivityTypeService, ActivityTypeStatusService,
    AppointmentCapacityService, AttorneyRoleService, BillableItemTypeService,
    CaseStatusService, CaseTypeService, CaseTypeAttorneyRoleService,
    CountyService, ExceptionStatusService, ExceptionAttachmentService,
    EducationalSettingService, GenderService, HearingTypeService,
    InvoiceStatusService, InvolvedPersonTypeService,
    JudicialDistrictService, PermanencyGoalService, PersonaApplicableToService,
    PrivilegeHolderService,
    PlacementService, PlacementTypeService, RaceEthnicityService,
    StateService, ActivityGroupService, PersonaService,
    PersonaPermissionService, PermissionService, UserStatusService,
    OfficeStatusService, OfficeService, ActivityStatusService,
    HelpArticleCategoryService, CaseTypeBillingAllocationService,
    BillableItemTypeStandardRateService, AppointmentEndReasonService,
    ExcessFeesRequestStatusService, AppointmentAttachmentService,
    ChildPartyService, ContactService, HelpArticleService,
    UserService, ApplicationSettingsService, ActivityAttachmentService,
    RequestTypeService, ChildPartyCourtAttendanceOptionService,
    SavedPlacementService, AppointmentOfficeAccessRequestReasonService,
    AppointmentOfficeAccessRequestStatusService, VendorService,
    HelpTextService, UrlLinkTextService, BestInterestsContinuationReasonService,
    RoleTypeService,
  ) {
    const self = this;

    self.appSettings = undefined;

    const expirationTimeMillis = 900000;

    self.createLookupList = function(lookupList) {
      if (lookupList.lookup) {
        return lookupList;
      }
      lookupList._idMap = {};
      lookupList.lookup = function(id, param, returnArray) {
        if (!param) {
          param = 'id';
        }
        if (!lookupList._idMap[param]) {
          lookupList._idMap[param] = {};
        }
        let item = lookupList._idMap[param][id];
        if (angular.isUndefined(item)) {
          if (returnArray) {
            item = [];
          }
          for (const datum of lookupList) {
            if (datum[param] === id) {
              if (returnArray) {
                item.push(datum);
              } else {
                item = datum;
                break;
              }
            } else if (!returnArray) {
              lookupList._idMap[param][id] = datum;
            }
          }
          if (angular.isUndefined(item)) {
            item = null;
          }
          lookupList._idMap[param][id] = item;
        }
        if (!item) {
          return undefined;
        }
        return item;
      };
      lookupList.clear = function() {
        lookupList.length = 0
        lookupList._idMap = {};
      }
      lookupList.expiration = new Date(new Date().getTime()
        + expirationTimeMillis);
      return lookupList;
    };

    const serviceFunctionMappings = {
      ActivityStatus: ActivityStatusService.get,
      ActivityGroup: ActivityGroupService.get,
      ActivityType: ActivityTypeService.get,
      ActivityTypeStatus: ActivityTypeStatusService.get,
      AppointmentCapacity: AppointmentCapacityService.get,
      AppointmentEndReason: AppointmentEndReasonService.get,
      AppointmentOfficeAccessRequestReason: AppointmentOfficeAccessRequestReasonService.get,
      AppointmentOfficeAccessRequestStatus: AppointmentOfficeAccessRequestStatusService.get,
      AttorneyRole: AttorneyRoleService.get,
      BillableItemType: BillableItemTypeService.get,
      CaseStatus: CaseStatusService.get,
      CaseType: CaseTypeService.get,
      CaseTypeAttorneyRole: CaseTypeAttorneyRoleService.get,
      CaseTypeBillingAllocation: CaseTypeBillingAllocationService.get,
      County: CountyService.get,
      EducationalSetting: EducationalSettingService.get,
      ExceptionStatus: ExceptionStatusService.get,
      ExcessFeesRequestStatus: ExcessFeesRequestStatusService.get,
      Gender: GenderService.get,
      ChildPartyCourtAttendanceOption: ChildPartyCourtAttendanceOptionService.get,
      HearingType: HearingTypeService.get,
      HelpArticleCategory: HelpArticleCategoryService.get,
      InvoiceStatus: InvoiceStatusService.get,
      InvolvedPersonType: InvolvedPersonTypeService.get,
      JudicialDistrict: JudicialDistrictService.get,
      Office: OfficeService.get,
      OfficeStatus: OfficeStatusService.get,
      PermanencyGoal: PermanencyGoalService.get,
      Permission: PermissionService.get,
      PersonaPermission: PersonaPermissionService.get,
      Persona: PersonaService.get,
      PersonaApplicableTo: PersonaApplicableToService.get,
      Placement: PlacementService.get,
      PlacementType: PlacementTypeService.get,
      PrivilegeHolder: PrivilegeHolderService.get,
      RaceEthnicity: RaceEthnicityService.get,
      RequestType: RequestTypeService.get,
      RoleType: RoleTypeService.get,
      State: StateService.get,
      User: UserService.get,
      UserStatus: UserStatusService.get,
      BillableItemTypeStandardRate: BillableItemTypeStandardRateService.get,
      AppointmentEndReasonService: AppointmentEndReasonService.get,
      Vendor: VendorService.get,
      HelpText: HelpTextService.get,
      UrlLinkText: UrlLinkTextService.get,
      BestInterestsContinuationReason: BestInterestsContinuationReasonService.get,
    };
    let data = {};

    self.load = function(type, includeInactive) {
      let typeName = type
      if (includeInactive) {
        typeName += '-all'
      }
      return serviceFunctionMappings[type]({
        limit: 1000,
        activeOnly: !includeInactive,
      }).$promise.then(
        function(resp) {
          data[typeName] = self.createLookupList(resp.list);
          return data[typeName];
        },
      );
    };

    self.get = function(type, includeInactive) {
      const defer = $q.defer();
      let typeName = type
      if (includeInactive) {
        typeName += '-all'
      }
      if (data[typeName] && data[typeName].expiration > new Date()) {
        defer.resolve(data[typeName]);
      } else {
        defer.resolve(self.load(type, includeInactive));
      }
      return defer.promise;
    };

    self.remove = function(type, includeInactive) {
      if (includeInactive) {
        type += '-all'
      }
      data[type] = undefined;
    };

    self.clearAll = () => data = {};

    self.dummyList = self.createLookupList([]);
    self.dummyList.valueOf = () => 0

    self.resetSearch = function() {
      ActivityAttachmentService.patch();
      ActivityGroupService.patch();
      ActivityStatusService.patch();
      ActivityTypeService.patch();
      AppointmentAttachmentService.patch();
      AppointmentCapacityService.patch();
      AppointmentEndReasonService.patch();
      AppointmentOfficeAccessRequestReasonService.patch();
      AppointmentOfficeAccessRequestStatusService.patch();
      AttorneyRoleService.patch()
      BillableItemTypeService.patch();
      CaseTypeService.patch();
      ChildPartyService.patch();
      ChildPartyCourtAttendanceOptionService.patch();
      ContactService.patch();
      CountyService.patch();
      EducationalSettingService.patch();
      ExceptionAttachmentService.patch();
      GenderService.patch();
      HearingTypeService.patch();
      HelpArticleService.patch();
      HelpArticleCategoryService.patch();
      InvolvedPersonTypeService.patch();
      JudicialDistrictService.patch();
      OfficeService.patch();
      PermanencyGoalService.patch();
      PermissionService.patch();
      PersonaService.patch();
      PlacementService.patch();
      PlacementTypeService.patch();
      PrivilegeHolderService.patch();
      RaceEthnicityService.patch();
      RequestTypeService.patch();
      RoleTypeService.patch();
      SavedPlacementService.patch();
      StateService.patch();
      UserService.patch();
      VendorService.patch();
      HelpTextService.patch();
      UrlLinkTextService.patch();
      BestInterestsContinuationReasonService.patch();
    }

    self.loadAppSettings = function() {
      if (angular.isUndefined(self.appSettings)) {
        self.promise = ApplicationSettingsService.get({
          limit: 1000,
        }).$promise.then(
          function(response) {
            self.appSettings = response.list;
          },
        );
      }
    }

    self.getAppSetting = function(key) {
      const defer = $q.defer();
      if (self.appSettings) {
        for (const setting of self.appSettings) {
          if (setting.key === key) {
            defer.resolve(setting);
          }
        }
      } else {
        ApplicationSettingsService.get({
          limit: 1000,
        }).$promise.then(
          function(response) {
            self.appSettings = response.list;
            for (const setting of self.appSettings) {
              if (setting.key === key) {
                defer.resolve(setting);
              }
            }
          },
        );
      }
      return defer.promise;
    }
  },
]);
