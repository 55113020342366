angular.module('CaresApp').factory('AppointmentNoteService', function(
  $resource) {
  return $resource('api/appointmentNotes/:id', {
    id: "@id",
  }, {
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
  });
});
