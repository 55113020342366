angular.module('CaresApp').factory('CaseTypeBillingAllocationService', function($resource) {
  return $resource('api/caseTypeBillingAllocations/:id', {
    id: "@id",
  }, {
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
  });
});
