angular.module('CaresApp').constant('formatFactory', {
  currency: {
    pattern: /^[(\-+]?\d*,?\d*\.?\d+[)]?$/,
    patternError:
      'This field must be formatted as Currency.<br/>A sample valid input looks like: 1000.00',
    replace: /[,$]/g,
    symbol: '$',
  },
  percent: {
    pattern: /^\d+(\.\d+)?$/,
    patternError: 'The value you entered is not a valid percentage.',
    replace: /[,%]/g,
  },
  phone: {
    pattern: /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
    patternError:
      'This field must be formatted as a Phone Number.<br/>A sample valid input looks like ' +
      '123-456-7890',
    replace: /[-.()\sa-zA-Z]/g,
  },
  caseSeq: {
    pattern: /^[0-9]{6}$/,
  },
  cents: {
    pattern: /^[(\-+]?\d*,?\d*\.?\d+[)]?$/,
    patternError:
      'This field must be formatted as Currency.<br/>A sample valid input looks like: 1000.00',
    replace: /[,$]/g,
    symbol: '$',
  },
  thousands: {
    pattern: /^[(\-+]?\d*,?\d*\.?\d+[)]?$/,
    patternError:
      'This field must be formatted as Currency.<br/>A sample valid input looks like: 1000.000',
    replace: /[,$]/g,
    symbol: '$',
  },
});
