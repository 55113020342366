angular.module('CaresApp').factory('PersonaPermissionService', function($resource) {
  return $resource('api/personaPermissions/:id', {
    id: "@id",
  }, {
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
  });
});
