angular.module('CaresApp').factory('CaseTypeAttorneyRoleService', function($resource) {
    return $resource('api/caseTypeAttorneyRoles/:id', {
      id: "@id",
    }, {
      'update': {
        method: 'PUT',
      },
      'query': {
        method: 'GET',
        isArray: false,
      },
      'patch': {
        method: 'PATCH',
      },
    });
  });
  