angular.module('CaresApp').factory('JudicialDistrictService', function($resource) {
  return $resource('api/judicialDistricts/:id', {
    userid: "@id",
  }, {
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
    'patch': {
      method: 'PATCH',
    },
  });
});
