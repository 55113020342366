angular.module('CaresApp').service('OcrAuth', [
  '$rootScope',
  '$cookies',
  '$interval',
  '$localStorage',
  function($rootScope, $cookies, $interval, $localStorage) {
    const self = this;
    const COOKIE_CHECK_INTERVAL = 2000

    if (!$cookies.get("ocr-session-id")
      && $localStorage.sessionData
      && $localStorage.sessionData.expiry
    ) {
      self.loggedIn = new Date($localStorage.sessionData.expiry).getTime() < (new Date()).getTime()
    } else if ($cookies.get("ocr-session-id")) {
      self.loggedIn = new Date($cookies.get("ocr-session-id").expiry).getTime()
        < (new Date()).getTime()
    } else {
      self.loggedIn = false
    }

    Object.defineProperty(self, 'session', { get: () => $localStorage.sessionData })
    self.setSession = function(session) {
      if (session) {
        session.expiry = new Date(session.expiry)
        if (session.expiry.getTime() < (new Date()).getTime()) {
          session = undefined
        }
      }
      const oldSession = self.session
      $localStorage.sessionData = session;
      if (!session) {
        $rootScope.$broadcast('SessionChanged')
        self.loggedIn = false;
        delete $localStorage.sessionData
        $cookies.remove("ocr-session-id");
        return;
      }
      self.loggedIn = true;
      $localStorage.sessionData = session;
      $cookies.put("ocr-session-id", self.session.id, {
        expires: self.session.expiry,
        secure: true,
      });
      $rootScope.$broadcast('SessionChanged', oldSession, self.session)
    }
    self.setSession($localStorage.sessionData)

    self.setAuthSession = function(authSession) {
      if (authSession) {
        authSession.expiry = new Date(authSession.expiry)
        if (authSession.expiry.getTime() < (new Date()).getTime()) {
          authSession = undefined
        }
      }
      $localStorage.authSessionData = authSession;
      if (!authSession) {
        delete $localStorage.authSessionData
        $cookies.remove("ocr-2fa-session-id");
        return;
      }
      $localStorage.authSessionData = authSession;
      $cookies.put("ocr-2fa-session-id", authSession.id, {
        expires: authSession.expiry,
        secure: true,
      });
    }
    self.setAuthSession($localStorage.authSessionData)

    // Allows this to act as an interceptor for $http service
    self.request = function(config) {
      if (!(config.url.startsWith('/api/') || config.url.startsWith('api/'))) {
        return config;
      }
      // Sometimes the cookie gets cleared (no idea why; it's not in our code).
      // This ensures that it's set. We want to keep it set in case the user
      // Needs to interact with the backend outside of angularjs (e.g. downloading
      // an attachment).
      self.loadCookie()
      if (self.session) {
        config.headers['X-Ocr-Session-Id'] = self.session.id
      }
      return config
    }

    self.loadCookie = function() {
      if ($cookies.get('ocr-session-id')) {

      } else if (self.session) {
        $cookies.put("ocr-session-id", self.session.id, {
          expires: new Date(self.session.expiry),
          secure: true,
        });
      } else if ($localStorage.sessionData) {
        self.setSession($localStorage.sessionData)
      } else {
        self.setSession(undefined);
      }
    }

    self.checkSecondAuth = function() {
      if ($cookies.get('ocr-2fa-session-id')) {
        return true;
      }
      return false;
    }

    self.isExpired = function() {
      if (!self.session) {
        return true;
      }
      if (new Date(self.session.expiry).getTime() < (new Date()).getTime()) {
        return true;
      }
      return false;
    }

    self.isPersona = function(persona_name) {
      if (self.session
        && angular.isDefined(self.session.user)
        && angular.isDefined(self.session.user.persona)
      ) {
        return self.session.user.persona.name === persona_name;
      }
      return false;
    }

    self.checkPermission = function(permission) {
      if (self.session
        && angular.isDefined(self.session.user)
        && angular.isDefined(self.session.user.permissions)
      ) {
        return self.session.user.permissions.includes(permission);
      }
      return false;
    }

    self.hasAnyPermission = function(permissions) {
      if (!self.session
        || angular.isUndefined(self.session.user)
        || angular.isUndefined(self.session.user.permissions)
      ) {
        return false
      }
      for (const permission of permissions) {
        if (self.session.user.permissions.includes(permission)) {
          return true;
        }
      }
      return false;
    }

    // Sometimes the cookie gets cleared (no idea why; it's not in our code).
    // This ensures that it's set. We want to keep it set in case the user
    // Needs to interact with the backend outside of angularjs (e.g. downloading
    // an attachment).
    $interval(() => self.loadCookie(), COOKIE_CHECK_INTERVAL, 0, false);
  },
]);
