angular.module('CaresApp').service('SessionService', [
  '$http',
  '$location',
  '$q',
  'OcrAuth',
  function($http, $location, $q, OcrAuth) {
    const self = this;

    self.renew = function() {
      $http.post("/api/sessions", {
        sessionId: OcrAuth.session.id,
      })
        .then(function(response) {
          OcrAuth.setSession(response.data);
        })
        .catch(function(error) {
          console.error("Error renewing session", error);
        });
    }

    self.signout = function() {
      self.close().then(function() {
        $location.path("/");
      });
    }

    self.close = function() {
      if (OcrAuth.session) {
        return $http.delete(`/api/sessions/${OcrAuth.session.id}`)
          .then(function(response) {
            OcrAuth.setSession(undefined);
            return response;
          })
          .catch(function(error) {
            console.error("Error deleting session: %O", error);
          });
      }
      return $q.when();
    }
  },
]);
