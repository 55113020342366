angular.module('CaresApp').service('OcrCacheBuster', [
  function() {
    const self = this;

    self.request = function(config) {
      if (!(config.url.startsWith('/api/') || config.url.startsWith('api/'))) {
        return config;
      }
      if (config.method !== 'POST') {
        if (!config.params) {
          config.params = {}
        }
        config.params.timestamp = new Date().getTime()
      }
      return config
    }
  },
]);

