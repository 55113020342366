angular.module('CaresApp').service('OcrInvoiceFlagsService', [
  'OcrLookups',
  function(OcrLookups) {
    const self = this;

    const getTotalTime = function(item) {
      let totalTime = item.totalMinutes || item.durationTimeTotal;
      if (item.maxDayTime) {
        ({ totalTime } = item.maxDayTime);
      } else if (item.longestActivityTime) {
        totalTime = item.longestActivityTime.totalMinutes;
      }
      return totalTime;
    };
    const hoursFlag = {
      name: 'hours',
      hoursWarning: 10 * 60,
      hoursCritical: 12 * 60,
      enabled: true,
      icon: 'schedule',
      tooltip: (item, messagePrefix) => {
        if (!messagePrefix) {
          messagePrefix = "Day(s) with";
        }
        const totalTime = getTotalTime(item);
        const hourLimit = totalTime >= hoursFlag.hoursCritical ? hoursFlag
          .hoursCritical : hoursFlag.hoursWarning;
        return `${messagePrefix} ${hourLimit / 60} hours or more.`;
      },
      ngClass: (item) => {
        const totalTime = getTotalTime(item);
        if (totalTime >= hoursFlag.hoursCritical) {
          return "ocr-invoice-tag-critical";
        } else if (totalTime >= hoursFlag.hoursWarning) {
          return "ocr-invoice-tag-warn";
        }
        return null;
      },
      show: (item) => getTotalTime(item) >= hoursFlag.hoursWarning,
    };
    hoursFlag.ariaLabel = hoursFlag.tooltip;

    self.flags = OcrLookups.createLookupList([hoursFlag]);
    for (const flag of self.flags) {
      const oldNgClass = flag.ngClass;
      flag.ngClass = function(item) {
        return `ocr-invoice-tag ${oldNgClass(item)}`;
      };
      if (flag.ngClass) {

      }
    }
    self.getFlagInfo = (flagName) => self.flags.lookup(flagName, 'name');
  },
]);
