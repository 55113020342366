angular.module('CaresApp').service('OcrViews', [
  '$location',
  '$rootScope',
  'OcrAuth',
  'OcrLookups',
  function($location, $rootScope, OcrAuth, OcrLookups) {
    const self = this;

    self.currentView = {
      key: "",
      name: "",
      description: "",
      order: -1,
      icon: "",
      permissions: [],
      permissionInclusion: "and",
    }
    self.views = OcrLookups.createLookupList([])

    let navItemIndex = 0
    const getNextViewIndex = function() {
      navItemIndex += 10
      return navItemIndex
    }
    const allViews = [
      {
        key: "home",
        name: "Home",
        description: "Announcements, case alerts, and appointment and invoice charts",
        order: getNextViewIndex(),
        icon: "home",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "appointment_groups",
        name: "Cases/Appointment Groups",
        description: "Create, edit, or delete the group names of associated appointments in my office.",
        order: getNextViewIndex(),
        icon: "assignment_ind",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "my_appointments",
        name: "Cases/My Appointments",
        description: "View the cases appointed to or accessed by my office.",
        order: getNextViewIndex(),
        icon: "assignment_ind",
        permissions: ["OFFICE_PUBLIC", "CASE_READ"],
      },
      {
        key: "cases",
        name: "Cases/Search Cases",
        description: "Search for cases and create new ones.",
        order: getNextViewIndex(),
        icon: "account_balance",
        columns: [
          "caseNumber",
          "caseType",
          "caseNickname",
          "judicialDistrict",
          "parties",
          "appointedAttorneys",
        ],
        // roles: [
        //   "admin"
        // ],
        headerNames: {
          caseNumber: "Case Number",
          caseType: "Case Type",
          caseNickname: "Case Nickname",
          judicialDistrict: "Judicial District",
          parties: "Parties",
          appointedAttorneys: "Appointed Attorneys",
        },
        // headerAriaLabels: {
        //   secondPeriodRaterSignatureDate: "Rater Signature Date for P2",
        //   secondPeriodEmployeeSignatureDate: "Employee Signature Date for P2",
        //   endOfCycleEmployeeSignatureDate: "Employee Signature Date for Evaluation",
        // }
        permissions: ["OFFICE_PUBLIC", "CASE_READ"],
      },
      {
        key: "office_activities",
        name: "Cases/Office Activities",
        description: "View the list of all activities for my office.",
        order: getNextViewIndex(),
        icon: "assignment_ind",
        permissions: ["OFFICE_PUBLIC", "ACTIVITY_READ"],
      },
      {
        key: "upcoming_activities",
        name: "Cases/Upcoming Activities",
        description: "View and create future activities for appointments.",
        order: getNextViewIndex(),
        icon: "assignment_ind",
        permissions: ["OFFICE_PUBLIC", "UPCOMING_READ"],
      },
      {
        key: "my_contacts",
        name: "Address Book/My Contacts",
        description:
          "Personal list of contacts that may be added to appointments as involved persons.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "contacts",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "my_placements",
        name: "Address Book/My Placements",
        description: "Personal list of placements that may be assigned to child parties.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "contacts",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "invoices",
        name: "Invoicing/Invoices",
        description: "Create, view, submit, approve, or reject invoices.",
        order: getNextViewIndex(),
        icon: "monetization_on",
        permissions: ["INVOICE_READ"],
      },
      {
        key: "core-export",
        name: "Invoicing/CORE Export",
        description: "Export invoices for CORE importing.",
        order: getNextViewIndex(),
        icon: "import_export",
        permissions: ["CORE_EXPORT"],
      },
      {
        key: "additional_funds",
        name: "Requests/Additional Funds",
        description: "View additional funding requests",
        order: getNextViewIndex(),
        usesSearch: false,
        icon: "attach_money",
        permissions: ["BILLING_REQUEST_CREATE", "BILLING_REQUEST_CREATE_OFFICEWIDE", "BILLING_REQUEST_REVIEW", "BILLING_CHANGE"],
        permissionInclusion: "or",
      },
      {
        key: "other_approvals",
        name: "Requests/Other Approvals",
        description: "View other approval requests",
        order: getNextViewIndex(),
        usesSearch: false,
        icon: "attach_money",
        permissions: ["EXPENSE_REQUEST_CREATE", "EXPENSE_REQUEST_REVIEW"],
        permissionInclusion: "or",
      },
      {
        key: "exception_log",
        name: "Requests/Exception Log",
        description: "View exception log",
        order: getNextViewIndex(),
        usesSearch: false,
        icon: "attach_money",
        permissions: ["EXCEPTION_READ"],
      },
      {
        key: "reports",
        name: "Reports",
        description: "Access reports.",
        order: getNextViewIndex(),
        icon: "view_column",
        permissions: ["REPORTS_READ"],
      },
      {
        key: "activity_groups",
        name: "Administration/Activity Groups",
        description: "Manage activity groups.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "activity_status",
        name: "Administration/Activity Status",
        description: "Manage activity status options.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "activity_types",
        name: "Administration/Activity Types",
        description: "Manage activity types.",
        usesSearch: true,
        order: getNextViewIndex(),
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "announcements",
        name: "Administration/Announcements",
        description: "Manage announcements.",
        order: 170,
        icon: "settings_applications",
        permissions: ["ADMIN_READ", "ANNOUNCEMENT_WRITE"],
      },
      {
        key: "appointment_capacities",
        name: "Administration/Appointment Capacities",
        description: "Manage appointment capacities.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "appointment_office_access_request_reasons",
        name: "Administration/Appointment Office Access Request Reasons",
        description: "Manage appointment office access request reasons.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "attorney_roles",
        name: "Administration/Attorney Roles",
        description: "Attorney Roles are linked to Case Types, so Roles cannot be deleted after creation and Role Type cannot be edited later. Changes to Role (name) will overwrite it if in use by any attorneys. Role Order determines its Child Party display order.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "child_party_court_attendance_options",
        name: "Administration/Child Party Court Attendance Options",
        description: "Manage child party court attendance options.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "child_party_end_reasons",
        name: "Administration/Child Party End Reasons",
        description: "Manage list of child party end reasons.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "best_interests_continuation_reasons",
        name: "Administration/Best Interests Continuation Reasons",
        description: "Manage best interests continuation reasons.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "billable_item_types",
        name: "Administration/Billable Item Types",
        description: "Manage billable item types.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "case_types",
        name: "Administration/Case Types",
        description: "Manage case types.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "counties",
        name: "Administration/Counties",
        description: "Manage list of counties.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "educational_settings",
        name: "Administration/Educational Settings",
        description: "Manage educational settings.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "genders",
        name: "Administration/Genders",
        description: "Manage genders.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "hearing_types",
        name: "Administration/Hearing Types",
        description: "Manage hearing types.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "help_text",
        name: "Administration/Help Text",
        description: "Manage help text",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "involved_person_types",
        name: "Administration/Involved Person Types",
        description: "Manage involved person types",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "judicial_districts",
        name: "Administration/Judicial Districts",
        description: "Manage judicial districts",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "offices",
        name: "Administration/Offices",
        description: "Manage offices.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "permanency_goals",
        name: "Administration/Permanency Goals",
        description: "Manage permanency goals.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "permissions",
        name: "Administration/Permissions",
        description: "Manage permissions.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "personas",
        name: "Administration/Personas",
        description: "Manage personas.",
        order: getNextViewIndex(),
        icon: "settings_applications",
        usesSearch: true,
        permissions: ["ADMIN_READ"],
      },
      {
        key: "placements",
        name: "Administration/Placements",
        description: "Manage placements.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "placement_types",
        name: "Administration/Placement Types",
        description: "Manage placement types.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "privilege_holder",
        name: "Administration/Privilege Holders",
        description: "Manage privilege holders",
        order: getNextViewIndex(),
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "race_ethnicities",
        name: "Administration/Race/Ethnicities",
        description: "Define race/ethnicity options",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        "key": "request_types",
        "name": "Administration/Request Types",
        "description": "Define request type options",
        "order": getNextViewIndex(),
        "icon": "settings_applications",
        "permissions": ["ADMIN_READ"],
      },
      {
        "key": "rules",
        "name": "Administration/Rules",
        "description": "Define appropriation unit rules",
        "order": getNextViewIndex(),
        "icon": "settings_applications",
        "permissions": ["ADMIN_READ"],
      },
      {
        key: "states",
        name: "Administration/States",
        description: "Manage states.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "url_link_text",
        name: "Administration/External Links",
        description: "Manage external links",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "users",
        name: "Administration/Users",
        description: "Manage users.",
        order: getNextViewIndex(),
        usesSearch: true,
        icon: "settings_applications",
        permissions: ["ADMIN_READ"],
      },
      {
        key: "general_settings",
        name: "User Settings/General Settings",
        description: "General user settings.",
        order: getNextViewIndex(),
        icon: "settings",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "change_password",
        name: "User Settings/Change Password",
        description: "Change user password.",
        order: getNextViewIndex(),
        icon: "settings",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "my_office",
        name: "User Settings/My Office",
        description: "Directory of all users in the current user's office.",
        usesSearch: true,
        order: getNextViewIndex(),
        icon: "settings",
        permissions: ["OFFICE_PUBLIC"],
      },
      {
        key: "help",
        name: "Help",
        description: "CARES application help pages.",
        order: getNextViewIndex(),
        icon: "help",
        permissions: ["OFFICE_PUBLIC", "HELP_READ"],
      },
    ]

    const filterViews = function() {
      self.views.clear()
      for (const item of allViews) {
        let shouldAdd = true
        if (item.permissions) {
          if (item.permissionInclusion && item.permissionInclusion === "or") {
            for (const permission of item.permissions) {
              if (OcrAuth.checkPermission(permission)) {
                shouldAdd = true
                break
              }
            }
          } else {
            for (const permission of item.permissions) {
              if (!OcrAuth.checkPermission(permission)) {
                shouldAdd = false
                break
              }
            }
          }
        }
        if (shouldAdd) {
          self.views.push(item)
        }
      }

      // set view hierarchy
      for (let c = 0; c < self.views.length; c++) {
        const view = self.views[c];
        view.shortName = view.name;
        if (view.name.indexOf("/") !== -1) {
          const parentName = view.name.substring(0, view.name.indexOf("/"));
          let parentView = self.views.lookup(parentName, 'name');
          if (!parentView) {
            parentView = {
              key: `${parentName.replace(/\s/, '').toLowerCase()}Parent`,
              name: parentName,
              hasChildren: true,
              children: [],
              icon: view.icon,
              order: view.order - 1,
            };
            self.views.push(parentView);
            parentView.children.push(view)
          }
          view.parent = parentView;
          if (!parentView.permissions) {
            parentView.permissions = view.permissions;
          } else {
            Array.prototype.push.apply(parentView.permissions, view.permissions);
          }
          parentView.permissions = parentView.permissions.filter(
            (str) => str,
          );
          view.shortName = view.name.substring(view.name.indexOf("/") + 1);
        }
      }
      self.views.sort((a, b) => a.order - b.order)
    }

    self.selectView = function(viewKey) {
      const oldView = self.currentView
      const newView = self.views.lookup(viewKey, 'key')
      if (!newView) {
        $location.path('/views/home');
        return
      }
      self.currentView = newView;
      $rootScope.$broadcast('ViewChanged', oldView, self.currentView)
    };


    if (OcrAuth.session && OcrAuth.session.user) {
      filterViews()
      $rootScope.$on('SessionChanged', filterViews)
    } else if (OcrAuth.session && !OcrAuth.session.user) {
      const unregister = $rootScope.$on('UserChanged', function() {
        filterViews()
        unregister()
        $rootScope.$on('SessionChanged', filterViews)
      })
    } else {
      $rootScope.$on('SessionChanged', filterViews)
    }
  },
],
);
