angular.module('CaresApp').factory('StateService', function($resource) {
  return $resource('api/states/:code', {
    stateCode: "@code",
  }, {
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
    'patch': {
      method: 'PATCH',
    },
  });
});
