angular.module('CaresApp').service('InvoiceDataService', [
  '$http',
  '$q',
  '$timeout',
  'ResourceService',
  function($http, $q, $timeout, ResourceService) {
    const self = this;

    function waitForResource(data, fn) {
      const checkResource = function() {
        ResourceService.get({
          id: data.data.uuid,
        }).$promise.then(
          function(resp) {
            // not deleting because we want to use the resource as a cache.
            // ResourceService.delete({
            //   id: data.data.uuid
            // });
            fn(resp);
          },
        ).catch(function(error) {
          if (error.data.status === 404) {
            //FIXME where is this coming from?
            /* eslint-disable no-undef */
            ip = $timeout(checkResource, 5000);
          }
        });
      };
      ip = $timeout(checkResource, 0); // check immediately the first time
      /* eslint-enable no-undef */
    }

    self.getStandardViewData = function(invoiceNumber, insufficientFunds, noCache) {
      const defer = $q.defer();
      const http_req = {
        method: 'GET',
        url: '/api/invoiceData/standard',
        params: {
          invoiceNumber: invoiceNumber,
          insufficientFunds: insufficientFunds,
          noCache: noCache
        },
      };
      $http(http_req).then(function(data) {
        waitForResource(data, function(resp) {
          defer.resolve(resp.body);
        });
      });
      return defer.promise;
    };

    self.getTotals = function(invoiceNumber, noCache) {
      const defer = $q.defer();
      const http_req = {
        method: 'GET',
        url: '/api/invoiceData/totals',
        params: {
          invoiceNumber: invoiceNumber,
          noCache: noCache
        },
      };
      $http(http_req).then(function(data) {
        waitForResource(data, function(resp) {
          if (resp.body.error) {
            ResourceService.delete({
              id: data.data.uuid
            });
            defer.reject(resp.body.error);
          } else {
            defer.resolve(resp.body[0]);
          }
        });
      });
      return defer.promise;
    };

    self.hasChanged = function(invoiceNumber) {
      const defer = $q.defer();
      const http_req = {
        method: 'GET',
        url: '/api/invoiceData/hasChanged',
        params: {
          invoiceNumber: invoiceNumber,
        },
      };
      $http(http_req).then(function(resp) {
        defer.resolve(resp.data);
      });
      return defer.promise;
    };

    self.getDaysData = function(invoiceNumber, insufficientFunds, noCache) {
      const defer = $q.defer();
      const http_req = {
        method: 'GET',
        url: '/api/invoiceData/days',
        params: {
          invoiceNumber: invoiceNumber,
          insufficientFunds: insufficientFunds,
          noCache
        },
      };
      $http(http_req).then(function(data) {
        waitForResource(data, function(resp) {
          defer.resolve(resp.body);
        });
      });
      return defer.promise;
    };
  },
]);
