angular.module('CaresApp').service('OcrUtils', [
  '$window',
  '$timeout',
  function($window, $timeout) {
    const self = this;

    self.get_today = function() {
      const today = new Date();
      today.setHours(0);
      today.setMinutes(0);
      today.setSeconds(0);
      today.setMilliseconds(0);
      return today;
    };

    Object.defineProperty(self, 'today', {
      get: self.get_today,
    });

    self.age = function(date) {
      if (!date) {
        return undefined;
      }

      const dob = new Date(date);
      dob.setHours(0);
      dob.setMinutes(0);
      dob.setSeconds(0);
      dob.setMilliseconds(0);

      let age = self.today.getFullYear() - dob.getFullYear() - 1;

      if (
        self.today.getMonth() > dob.getMonth()
        || (self.today.getMonth() === dob.getMonth() && self.today.getDate() >= dob.getDate())
      ) {
        age++;
      }

      return age;
    };

    self.setPageTitle = function(title) {
      if (!title || title.length === 0) {
        $window.document.title = 'OCR CARES';
      } else {
        $window.document.title = `${title} - OCR CARES`;
      }
    };

    self.firstDayOfPreviousMonth = function() {
      const now = new Date();

      let year = now.getFullYear();
      let month = now.getMonth();

      if (month > 0) {
        month--;
      } else {
        month = 11; // 0-based!
        year--;
      }

      return new Date(year, month, 1, 0, 0, 0, 0);
    };

    self.lastDayOfPreviousMonth = function() {
      const now = new Date();

      const year = now.getFullYear();
      const month = now.getMonth();

      return new Date(year, month, 0, 0, 0, 0, 0);
    };

    self.removeFakePath = function(file) {
      const fakePathString = "C:\\fakepath\\";
      const index = file.indexOf(fakePathString);
      if (index === 0) {
        return file.substring(fakePathString.length);
      }
      return null;
    };

    self.getArrayElementById = function(array, idField, id) {
      if (array) {
        for (let c = 0; c < array.length; c++) {
          if (array[c][idField] === id) {
            return array[c];
          }
        }
      }
      return undefined;
    };

    self.getUserFullName = function(user) {
      if (!user) {
        return "";
      }

      if (!user.lastName) {
        return "";
      }

      let fullName = user.lastName;

      if (user.suffix) {
        fullName += ` ${user.suffix}`;
      }

      fullName += `, ${user.firstName}`;

      if (user.middleName) {
        fullName += ` ${user.middleName}`;
      }

      return fullName;
    };

    self.getChildPartyFullName = self.getUserFullName; // same for now

    self.getCurrentFiscalYear = function() {
      const now = new Date();
      if (now.getMonth() < 6) {
        return now.getFullYear();
      }
      return now.getFullYear() + 1;
    };

    self.getCurrentFiscalPeriod = function() {
      const now = new Date();
      if (now.getMonth() >= 6) {
        return now.getMonth() - 5;
      }
      return now.getMonth() + 7;
    };

    self.makeVeryShallowCopy = function(dict) {
      const newDict = {};
      for (const propName in dict) {
        const propValue = dict[propName];
        if (!angular.isObject(propValue) || propValue instanceof Date) {
          newDict[propName] = propValue;
        }
      }
      return newDict;
    };

    self.getNegativeAmountStyle = function(item) {
      if (angular.isNumber(item)) {
        if (item < 0) {
          return { 'color': 'rgb(237, 42, 42)' }
        }
        return {}
      } else if (item instanceof Object) {
        for (const k in item) {
          const res = self.getNegativeAmountStyle(item[k])
          if (Object.keys(res).length > 0) {
            return res
          }
        }
      }
      return {}
    }

    self.debounce = function(fn, delay) {
      let timeout = null;
      return function() {
        if (timeout) {
          clearTimeout(timeout);
        }
        timeout = $timeout(fn, delay);
      };
    };
  },
]);
