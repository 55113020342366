angular.module('CaresApp').factory('InvoiceService', function($resource) {
  return $resource('api/invoices/:id', {
    id: "@id"
  }, {
    'save': {
      method: 'POST',
      interceptor: {
        response: function(response) {
          const result = response.resource;
          result.$status = response.status;
          return result;
        },
      },
    },
    'update': {
      method: 'PUT',
    },
    'query': {
      method: 'GET',
      isArray: false,
    },
  });
});
